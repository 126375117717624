<template>
  <div 
    v-expose="{
      id: '1-11-1-209',
      data: shippingAddItemAnalysis
    }"
    v-tap="{
      id: '1-11-1-210',
      data: shippingAddItemAnalysis
    }"
    class="shipping-method-item__add"
    @click.stop="handleAddFree"
  >
    <div 
      class="shipping-method-item__add-desc" 
      v-html="hints"
    >
    </div>
    <span class="shipping-method-item__add-btn">
      {{ language.SHEIN_KEY_PWA_29778 }}
    </span>
  </div>
</template>

<script setup>
import { useMapState, useStore } from 'public/src/pages/checkout/hooks/index.js'
import { computed, ref, watch, defineEmits } from 'vue'
import { template } from '@shein/common-function'
import AddOnItemPop  from 'public/src/pages/common/addOnItem/index.js' // 促销和免邮凑单
import AddOn from 'public/src/pages/common/add-on/index.js' // 券凑单（中间层融合）
import { deepCopy } from 'public/src/pages/checkout/utils.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const props = defineProps({
  addItemContentInfo: {
    type: Object,
    default: () => {}
  },
  mallCode: {
    type: [String, Number],
    default: ''
  },
})
const store = useStore()
const { language, buyNowNo, checkout, shippingAddStatusInfo } = useMapState(['language', 'buyNowNo', 'checkout', 'shippingAddStatusInfo'])

const cartsGoodsIdAll = ref([])
const deepCopyTransType = ref('')
let deepCopyType = ref('')
const addFreeStatus = ref(0)
// 当前是否打开了凑单
const visibleStatus = ref(false)

const contentInfo = computed(() => {
  // 当前凑单弹窗组件未打开
  if(!shippingAddStatusInfo?.value?.transportType){
    return props.addItemContentInfo
  }

  let data = {
    transportType: deepCopyTransType.value,
    type: deepCopyType.value,
    progress: props.addItemContentInfo?.progress
  }

  let info, 
      diffPrice,
      detail
  if(data.type == 'activity') {
    if(!!props.addItemContentInfo?.transportType && props.addItemContentInfo?.type == data.type) {
      info = props.addItemContentInfo?.info
      diffPrice = props.addItemContentInfo?.diffPrice
      detail = props.addItemContentInfo?.detail
    } else {
      info = props.addItemContentInfo?.activityContent
      diffPrice = props.addItemContentInfo?.activityContent?.[props.mallCode]?.shippingInfoAvailable?.price
      detail = props.addItemContentInfo?.activityContent?.[props.mallCode]
    }
  } else {
    if(!!props.addItemContentInfo?.transportType && props.addItemContentInfo?.type == data.type) {
      info = props.addItemContentInfo?.info
      diffPrice = props.addItemContentInfo?.diffPrice
    } else {
      info = props.addItemContentInfo?.couponContent
      diffPrice = props.addItemContentInfo?.couponContent?.needPrice
    }
  }
  
  return Object.assign(data, { info, diffPrice, detail })
})
// activity,coupon
const type = computed(() => { 
  return contentInfo?.value?.type || ''
})
const diffPriceAmount = computed(() => {
  return contentInfo?.value?.diffPrice?.amount
})
const Need_Qs_Freight = computed(() => {
  return [ 108 ].includes(+checkout.value?.default_address?.countryId)
})
// 当前mall下判别quickShip商品
const isProductQuickShip = computed(() => {
  const currentMall = (checkout.value.results.mallShippingMethods?.mall_list || []).find(f => f.mall_code == props.mallCode)
  return Need_Qs_Freight.value ? 0 : (currentMall?.desc_quick_ship_time || currentMall?.quick_ship_time)
})
// 购物车整个数据
const cartsInfo = computed(() => {
  const resultCarts = checkout.value.results?.carts?.cartInfo || {}
  return {
    carts: resultCarts?.source || [],
    cartInfo: {
      ...resultCarts,
      carts: resultCarts?.source || [],
    }
  }
})
const hints = computed(() => {
  // 优惠券门槛
  let minOrderPrice = contentInfo?.value?.info?.other_coupon_rule?.[0]?.min_order?.price || ''
  // 0门槛优惠券的优惠券凑单文案
  if(parseFloat(minOrderPrice) === 0 && type.value != 'activity') {
    return template(`<em>${language.value.SHEIN_KEY_PWA_30439}</em>`, language.value.SHEIN_KEY_PWA_31184)
  }
  return template(`<em>${contentInfo?.value?.diffPrice?.amountWithSymbol}</em>`, `<em>${language.value.SHEIN_KEY_PWA_30439}</em>`, type.value == 'activity' ? language.value.SHEIN_KEY_PWA_30440 : language.value.SHEIN_KEY_PWA_30441)
})
const shippingAddItemAnalysis = computed(() => {
  return {
    type: contentInfo?.value?.type || '-',
    mall_code: props.mallCode || '-',
    origin_price: (contentInfo?.value?.type == 'activity' ? contentInfo?.value?.detail?.origin_price?.amountWithSymbol : contentInfo?.value?.info?.other_coupon_rule?.[0]?.minOrderPrice?.amountWithSymbol) || '-',
    shipping_method: contentInfo?.value?.transportType || '-',
    // coupon_dimension 1平台券，2mall券
    goods_range: (contentInfo?.value?.type == 'activity' ? 1 : (contentInfo?.value?.info?.coupon_dimension == 1 ? 0 : 1)) || '-',
    progress: contentInfo?.value?.progress,
  }
})

// watch([diffPriceAmount, upDateLock, cartsLock], async ([newDiffPriceAmount, newUpDateLock, newCartsLock], [oldDiffPriceAmount]) => {
//   console.log('newDiffPriceAmount, newUpDateLock, newCartsLock', newDiffPriceAmount, newUpDateLock, newCartsLock)
//   if(newUpDateLock && newCartsLock && newDiffPriceAmount != oldDiffPriceAmount){
//     upDateLock.value = false
//     cartsLock.value = false
//     const params = await propsParamsResult()
//     AddOnItemPop.updateOpts(params)
//   }
// })
watch([diffPriceAmount], async ([newDiffPriceAmount], [oldDiffPriceAmount]) => {
  if(visibleStatus.value && newDiffPriceAmount != oldDiffPriceAmount){
    const params = await propsParamsResult()
    // console.log(`当前打开的${type.value}`, contentInfo.value)
    // 仅处于打开状态才能更新, 规避券弹窗使用旧的方法更新
    AddOnItemPop.isOpened() && AddOnItemPop.updateOpts(params)
  }
})
const emits = defineEmits(['addSuccess'])

const propsParamsResult = async () => {
  let propsParams = {}
  if(type.value == 'coupon'){
    const couponInfo = contentInfo?.value?.info
    const couponCode = couponInfo?.coupon || ''
    if(!couponCode) throw new Error('couponCode no Data')
    propsParams = {
      props: {
        type: 'coupon',
        coupon: couponCode,
        config: {
          isBuyNow: !!buyNowNo.value,
          casualCheckoutNo: buyNowNo.value,
          continueBtnToClose: true,
          continueBtnUseLangKey: 'SHEIN_KEY_PWA_30444',
          isCloseBusinessCartEntry: true,
          isCloseIncentivePoint: true,
        },
        queryInfo: {
          addOnType: 6,
          sceneId: 152,
          goodsIds: cartsInfo.value?.carts.map(item => item?.product?.goods_id), // 商品ID,
          cateIds: cartsInfo.value?.carts.map(item => item?.product?.cat_id), // 类目ID
          quickship_prior: isProductQuickShip.value ? 1 : 0
        },
        saInfo: {
          promotion_code: couponCode,
          activity_from: 'checkout_shipping_coupon_add',
          abtest: 'addnewhotJson',
          state: 'checkout_shipping_coupon_add'
        }
      }
    }
  }else{
    const activityFreeParams = {
      cartsApiData: cartsInfo.value?.cartInfo,
      mallCode: props.mallCode,
      mallShippingInfo: contentInfo?.value.info,
      isQuickship: !!isProductQuickShip.value,
      transportType: contentInfo.value.transportType,
      isBuyNow: !!buyNowNo.value,
      casualCheckoutNo: buyNowNo.value,
    }
    propsParams = AddOnItemPop.getOpts4FreeShippingFromCheckout(activityFreeParams)
  }

  // console.log('propsParams==', propsParams)
  return propsParams
}

// 调取计价接口，购物车接口
const changeDataApi = async () => {
  return new Promise((resolve) => {
    store.dispatch('checkout/orignMallCacuTotal', {
      opt: {
        ...window?.shem_caculate_data,
        pureCallBack: (res) => {
          if(res.code != 0){
            AddOnItemPop.close()
            AddOn.close()
          }
        }
      }
    })
    // 更新购物车
    store.dispatch('checkout/updateCart', { noUpdataShipping: 1, cartCallback: (res) => {
      if(res?.code){
        return resolve(true)
      } else {
        return resolve(false)
      }
    } })
  })
}

// 免邮凑单弹窗 activity（活动免邮凑单） | coupon（优惠券免邮凑单）
const handleAddFree = async () => {
  // console.log(`当前打开的${type.value}`, contentInfo.value)
  changeIsshow(true)
  const propsParams  = await propsParamsResult()
  const addOnConfig = {
    ...propsParams,
    on: { // 钩子
      close: (val) => {
        changeModelCartBag(val)
      },
      beforeOpen: (val) => {
        // console.log('beforeOpen', val)
      },
      updateCart: async (cartInfo) => {
        await changeDataApi()
        addOnUpdateCarts(cartInfo)
      }
    },
  }
  if (type.value == 'coupon') {
    AddOn.open(addOnConfig) // 券走中间层融合
  } else {
    AddOnItemPop.open(addOnConfig)
  }
}

const changeIsshow = (val) => {
  if(val){
    deepCopyTransType.value = deepCopy(props.addItemContentInfo.transportType)
    deepCopyType.value = deepCopy(props.addItemContentInfo.type)
  }
  visibleStatus.value = val
  store.commit('checkout/updateShippingAddStatusInfo', {
    transportType: val ? contentInfo?.value.transportType : '',
  })
}

// 免邮凑单加车数据更新
const addOnUpdateCarts = (cartDatas) => {
  cartDatas && cartsGoodsIdAll.value.push(1)
}
// 关闭免邮弹窗，刷新接口
const changeModelCartBag = (freeInfo) => {
  addFreeStatus.value = type.value == 'coupon' 
    ? [1, 2].includes(+freeInfo?._data?.is_satisfied) ? 1 : 0
    : freeInfo.isActivityStop || freeInfo.isActivityStop ? 1 : 0
  changeIsshow(false)
  // 凑单状态
  // console.log('addFreeStatus', addFreeStatus.value, freeInfo)
  if(cartsGoodsIdAll.value.length){
    cartsGoodsIdAll.value = []
    // 凑单弹窗关闭后的回调
    // console.log('凑单成功==', cartsGoodsIdAll)
    emits('addSuccess', { status: addFreeStatus.value })
  } else {
    // console.log('无加购')
  }
}

</script>

<style lang="less" scoped>
.shipping-method-item__add {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8/75rem 16/75rem;
  background: #F3FAF6;
  .font-dpr(24px);
  font-weight: 600;
  position: relative;

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    background: #F3FAF6;
    top: 0;
    .left(20px);
    transform: translateY(-50%) rotate(45deg);
  }

  .shipping-method-item__add-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .shipping-method-item__add-btn {
    display: inline-block;
    .margin-l(8/75rem);
    padding: 8/75rem 20/75rem;
    min-width: 88/75rem;
    max-width: 140/75rem;
    border-radius: 100px;
    border: 1px solid @sui_color_brand;

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>

<style lang="less">
.shipping-method-item__add {
  .shipping-method-item__add-desc {
    em {
      color: @sui_color_safety;
    }
  }
}
</style>
