<template>
  <div
    v-expose="{
      id:'expose_unusedbenefits_pickupbuy.page_checkout:simple',
    }"
    v-tap="{
      id: 'click_unusedbenefits_pickupbuy.page_checkout:simple',
    }"
    class="coupon-auto-tips__wrap"
    @click="handleClick"
  >
    <div
      class="tip-left"
      v-html="tpisText"
    >
    </div>
    <div class="tip-right">
      <i class="icon suiiconfont sui_icon_viewmore_14px_2"></i>
    </div>
  </div>
</template>
  
<script setup>
import { ref, onMounted, onUnmounted } from 'vue' 
defineProps({
  tpisText: {
    type: String,
    default: ''
  }
})
const timer = ref(0)
const emit = defineEmits(['click-hiden-pickBuyTips'])



const handleClick = () => {
  emit('click-hiden-pickBuyTips')
  const together = document.querySelector('.together-buy-container')
  if(together){
    const topH = together.offsetTop
    const headH = document.querySelector('#checkoutHeader')?.clientHeight || 0
    const noticeH = document.querySelector('.checkout-notice')?.clientHeight || 0
    window.scrollTo({
      top: topH - (headH + noticeH),
      behavior: 'smooth',
    })
  }
}




const elementRectInView = (elementRect) => {
  const rect = elementRect.getBoundingClientRect()
  const isInView = (
    rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth))
  return {
    isInView,
    rect
  }
}

const scrollTo = () => {
 
  const together = document.querySelector('.together-buy-container')
  if(!together) return
  
  window.requestAnimationFrame(() => {
   
    const { rect } = elementRectInView(together)
  
    if(rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 100) {
      emit('click-hiden-pickBuyTips')
    }
  })
}

onMounted(() => {
  
  window.addEventListener('scroll', scrollTo)
})

onUnmounted(() => {
  
  clearTimeout(timer.value)
  window.removeEventListener('scroll', scrollTo)
})

clearTimeout(timer.value)
timer.value = setTimeout(() => {
 
  emit('click-hiden-pickBuyTips')
  clearTimeout(timer.value)
}, 8000) 

</script>
  
<style lang="less" scoped>
  .coupon-auto-tips_toast {
    width: 24/37.5rem;
    height: 16/37.5rem;
    margin-left: 4/37.5rem;
  }
  .coupon-auto-tips__wrap {
    position: absolute;
    top: -20/75rem;
    transform: translateY(-100%);
    background-color: rgba(0,0,0,.8);
    width: 702/75rem;
    height: auto;
    padding: 24/75rem;
    border-radius: 8/75rem;
    .flexbox();
    align-items: center;
    justify-content: space-between;
    color: @sui_color_white;

    .tip-right {
      .padding-l(12/75rem);
    }
  }
</style>
  
  
