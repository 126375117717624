<template>
  <div 
    v-if="!!largeShipGoods.length"
    class="shipping-method-item__large-goods"
    :class="wrapClassName"
  >
    <div
      v-for="(item, index) in largeShipGoods.slice(0, 3)"
      :key="item.id"
      :class="`goods-img-${index+1}`"
    >
      <CommonImage 
        class="goods-img"
        :item="item"
        :cut-img-width="32"
      >
        <template #others>
          <em 
            v-if="index == 0 && largeShipGoods.length > 1"
            class="goods-num"
          >
            {{ goodsNum }}
          </em>
        </template>
      </CommonImage>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CommonImage from 'public/src/pages/checkout/components/CommonImage.vue'

const props = defineProps({
  largeShipGoods: {
    type: Array,
    default: () => []
  },
})

const wrapClassName = computed(() => {
  const tempList = props.largeShipGoods?.slice(0, 3) || []
  const nameList = ['', 'large-goods-len1', 'large-goods-len2', 'large-goods-len3']

  return nameList[tempList.length]
})

const goodsNum = computed(() => {
  const num = props.largeShipGoods.reduce((prev, next) => prev = Number(prev + next?.quantity), 0)
  return num > 99 ? '99+' : num
})

</script>

<style lang="less" scoped>
.shipping-method-item__large-goods {
  height: 64/75rem;
  position: relative;
  &.large-goods-len1 {
    width: 72/75rem;
  }
  &.large-goods-len2 {
    width: 96/75rem;
  }
  &.large-goods-len3 {
    width: 104/75rem;
  }

  .goods-img {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 4px;
    border: 0.5px solid #FFF;
    object-fit: cover;
  }
  .goods-img-1 {
    position: absolute;
    width: 64/75rem;
    height: 64/75rem;
    .left(0);
    top: 0;
    z-index: 3;
    transform: translateZ(3px);
  }
  .goods-img-2 {
    position: absolute;
    width: 56/75rem;
    height: 56/75rem;
    .left(24/75rem);
    top: 4/75rem;
    z-index: 2;
    transform: translateZ(2px);
  }
  .goods-img-3 {
    position: absolute;
    width: 48/75rem;
    height: 48/75rem;
    .left(40/75rem);
    top: 8/75rem;
    z-index: 1;
    transform: translateZ(1px);
  }

  .goods-num {
    position: absolute;
    bottom: 1px;
    .right(0);
    .font-dpr(16px);
    color: #FFF;
    padding: 0px 4px;
    border-radius: 4px 0px;
    background: rgba(0, 0, 0, 0.70);
  }
}
</style>
