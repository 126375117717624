<template>
  <div class="footer-had-pay">
    <div class="pay-block">
      <div
        ref="leftRef"
        class="pay-left"
      >
        <div class="pay-img">
          <img
            class="payment-src"
            :src="currentPaymentImg"
          />
        </div>
        <div
          ref="paymentNameRef"
          class="pay-name"
        >
          <span
            v-if="
              currentPayment.code === 'afterpay-card' &&
                afterPayVault.title &&
                afterPayVault.title === 'A' &&
                !signupPayVault?.[currentPayment.code]?.useOneTimePay
            "
            v-html="language.SHEIN_KEY_PWA_30872"
          ></span>
          <span v-else> {{ currentPaymentName }}</span>
        </div>
        <discount-zero
          ref="discountZeroRef"
          :item="currentPayment"
          :language="language"
          :nowShowTokenPayment="nowShowTokenPayment"
        />
        <span
          v-if="discountZeroRefIsWrapped"
          class="division"
        >
          +
        </span>
        <discount-tips
          ref="discountTipsRef"
          :checkout="checkout"
          :language="language"
          :price="price"
          :current-payment="currentPayment"
          :locals="locals"
          :icon-prefix="iconPrefix"
          :is-show-token-payment-by-abt="isShowTokenPaymentByAbt"
          :is-show-token-payment="isShowTokenPayment"
          :class="[
            {
              'discount-tips-wrapped': discountTipsRefIsWrapped
            }
          ]"
          @payment-options-oper="handlePaymentOptionsOper"
        />
      </div>
      <div class="pay-right">
        <div
          class="change-btn"
          @click="showSelectDrawer"
        >
          <span
            ref="btnRef"
            class="change-btn-text"
          >
            {{ language.SHEIN_KEY_PWA_29362 }}
          </span>
        </div>
      </div>
    </div>
    <CheckPpgaType
      v-if="isShowCheck"
      :language="language"
      :paypal-ga-vault="paypalGaVault"
      :checked-label="CHECKED"
      @toggle="handleTogglePpgaType"
    />
    <!-- <CheckAfterPayType
      v-if="isShowAfterpayCheck"
      :language="language"
      :afterPayVault="afterPayVault"
      :checked-label="AFTERPAY_CHECK_TYPE.CHECKED"
      @toggle="handleToggleAfterPayType"
    /> -->
    <CheckSignupPayType
      v-if="isShowSignupCheck"
      :language="language"
      :payment-code="currentPayment.code"
      :vault="signupPayVault[currentPayment.code]"
      :checked-label="SIGNUP_CHECK_TYPE.CHECKED"
      @toggle="handleToggleSignupType"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, defineEmits, watch } from 'vue'
import { template } from '@shein/common-function'
import DiscountTips from './discount-tips.vue'
import CheckPpgaType from 'public/src/pages/checkout/vue_tpls/CheckPpgaType.vue'
// import CheckAfterPayType from 'public/src/pages/checkout/vue_tpls/CheckAfterPayType.vue'
import CheckSignupPayType from 'public/src/pages/checkout/vue_tpls/CheckSignupPayType.vue'
import DiscountZero from './discount_zero.vue'
import {
  useStore,
  useMapGetters
} from 'public/src/pages/checkout/hooks/index.js'

// utils
import {
  PPGA_CHECK_TYPE,
  // AFTERPAY_CHECK_TYPE,
  SIGNUP_CHECK_TYPE
} from 'public/src/pages/common/constants'

const props = defineProps({
  checkout: { type: Object, default: () => ({}) },
  banksConfig: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  status: {
    type: Object,
    default: () => ({})
  },
  getImgUrl: {
    type: Function,
    default: () => {}
  },
  isTextOverflow: {
    type: Function,
    default: () => {}
  },
  price: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  iconPrefix: {
    type: String,
    default: ''
  },
  paymentMethodsProps: {
    type: Object,
    default: () => ({})
  },
  isShowTokenPaymentByAbt: {
    type: Boolean,
    default: false
  },
  defaultSelectTokenPay: {
    type: Object,
    default: () => ({})
  }
})

const emits = defineEmits([
  'payment-options-oper',
  'show-select-drawer',
  'handle-toggle-ppga-type'
])

const leftRef = ref(null)
const paymentNameRef = ref(null)
const btnRef = ref(null)
const discountZeroRef = ref(null)
const discountZeroRefIsWrapped = ref(false)
const discountTipsRef = ref(null)
const discountTipsRefIsWrapped = ref(false)
const store = useStore()
const { isSelectInstallmentTokenPay } = useMapGetters([
  'isSelectInstallmentTokenPay'
])

// 支付方式
const paymentMethods = computed(() => {
  return props.checkout?.results?.paymentMethods || []
})

// 当前支付方式
const currentPayment = computed(() => {
  return paymentMethods.value.find(item => item.code === props.status?.cardPay)
})

const itemCode = computed(() => {
  return currentPayment.value?.code?.toLowerCase() || ''
})

const nowShowTokenPayment = computed(() => {
  return (
    isShowTokenPayment.value &&
    (currentPayment.value?.card_token_list || [])?.find(
      item => item.id === props.defaultSelectTokenPay?.id
    )
  )
})

const installmentSelectBin = computed(() => {
  return store?.state?.checkout?.installmentSelectBin
})

const isUseNewCard = computed(() => {
  return store?.state?.checkout?.isUseNewCard
})

const nowInstallmentTokenPayment = computed(() => {
  const bin = installmentSelectBin.value || ''
  return (
    isSelectInstallmentTokenPay.value &&
    ((currentPayment.value?.card_token_list || [])?.find(
      item => item?.card_bin === bin
    ) || (currentPayment.value?.card_token_list || [])?.[0])
  )
})

const isShowTokenPayment = computed(() => {
  // 后端字段是否展示 token 支付
  const isShowTokenPaymentByBackEnd =
    currentPayment.value?.code === 'routepay-card' &&
    currentPayment.value?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
  // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
  return (
    isShowTokenPaymentByBackEnd &&
    props.isShowTokenPaymentByAbt &&
    (currentPayment.value?.card_token_list || [])?.length > 0
  )
})

const currentPaymentName = computed(() => {
  const signupInfo = props.paymentMethodsProps?.signupAccountInfo?.[currentPayment.value?.code]
  const signupVault = props.paymentMethodsProps?.signupPayVault?.[currentPayment.value?.code]
  if (itemCode.value == 'cod') {
    return currentPayment.value?.title
  }
  if (
    currentPayment.value?.code == 'PayPal-GApaypal' &&
    paypalGaVault.value?.valid
  ) {
    if (paypalGaVault.value?.express) {
      return props.language.SHEIN_KEY_PWA_25526
    }
    if (paypalGaVault.value?.type == PPGA_CHECK_TYPE.CHECKED) {
      return (
        props.paymentMethodsProps?.ppgvAccountInfo?.signUpEmail ||
        props.language.SHEIN_KEY_PWA_19684
      )
    }
  }
  if (
    currentPayment.value?.code == 'afterpay-card' &&
    afterPayVault?.value?.title && 
    !signupVault?.useOneTimePay
  ) {
    if (afterPayVault?.value?.title === 'A') {
      return props.language?.SHEIN_KEY_PWA_30872
    } else if (afterPayVault?.value?.title === 'B') {
      return props.language?.SHEIN_KEY_PWA_30873
    }
  }

  if (currentPayment.value?.code == 'afterpay-cashapp' && signupInfo?.signUpEmail && !signupVault?.useOneTimePay) {
    return template(signupInfo.signUpEmail, props.language?.SHEIN_KEY_PWA_33907)
  }

  if (currentPayment.value?.code == 'dlocal-nupay' && signupInfo?.id && !signupVault?.useOneTimePay) {
    return props.language?.SHEIN_KEY_PWA_33934
  }

  if (currentPayment.value?.code == 'klarna-klarna' && signupInfo?.signUpPhoneNumber && !signupVault?.useOneTimePay) {
    return template(signupInfo.signUpPhoneNumber, props.language?.SHEIN_KEY_PWA_33937)
  }

  if (isShowTokenPayment.value && nowShowTokenPayment.value) {
    return nowShowTokenPayment.value?.card_no
  }

  if (isSelectInstallmentTokenPay.value && nowInstallmentTokenPayment.value) {
    if (isUseNewCard.value) {
      return props.language.SHEIN_KEY_PWA_30049
    }
    return `${nowInstallmentTokenPayment.value?.card_no}${props.language.SHEIN_KEY_PWA_31887}`
  }

  return (
    (currentPayment.value?.bank_list?.length &&
      props.banksConfig?.[currentPayment.value?.code]?.bankCodeText) ||
    (currentPayment.value?.show_title && currentPayment.value?.title) ||
    ''
  )
})

const currentPaymentImg = computed(() => {
  return props.getImgUrl(currentPayment.value)
})

const paypalGaVault = computed(() => {
  return props.paymentMethodsProps?.paypalGaVault
})
const afterPayVault = computed(() => {
  return props.paymentMethodsProps?.afterPayVault
})
const signupPayVault = computed(() => {
  return props.paymentMethodsProps?.signupPayVault
})
const isShowCheck = computed(() => {
  return (
    currentPayment.value?.code === 'PayPal-GApaypal' &&
    currentPayment.value?.enabled &&
    paypalGaVault.value &&
    paypalGaVault.value?.valid &&
    paypalGaVault.value?.express
  )
})
// const isShowAfterpayCheck = computed(() => {
//   return (
//     currentPayment.value?.code === 'afterpay-card' &&
//     currentPayment.value?.enabled &&
//     afterPayVault?.value &&
//     afterPayVault?.value.valid
//   )
// })
const isShowSignupCheck = computed(() => {
  return (
    !['PayPal-GApaypal'].includes(currentPayment.value?.code) &&
    currentPayment.value?.enabled &&
    currentPayment.value?.needToSign &&
    signupPayVault.value?.[currentPayment.value?.code]?.valid
  )
})
const CHECKED = computed(() => {
  return PPGA_CHECK_TYPE?.CHECKED
})

watch(
  () => props.status?.cardPay,
  () => {
    if (typeof window === 'undefined') return
    setTimeout(() => {
      checkIfWrapped(leftRef.value)

      setRefIsWrapped()
    }, 0)
  },
  {
    immediate: true,
    deep: true
  }
)

const isWrapped = element => {
  // 如果没有前一个兄弟元素，则认为没有换行
  if (!element?.previousElementSibling || !element) {
    return false
  }
  // 获取当前元素及其前一个兄弟元素的顶部位置
  const currentTop = element?.getBoundingClientRect()?.top
  const siblingTop =
    element?.previousElementSibling?.getBoundingClientRect()?.top

  // 如果当前元素的顶部位置大于前一个兄弟元素的顶部位置，则认为发生了换行
  return currentTop > siblingTop
}

function setRefIsWrapped() {
  if (discountZeroRef.value?.$el?.$children?.length > 0) {
    discountZeroRefIsWrapped.value = isWrapped(discountZeroRef.value?.$el)
  }
  if (discountTipsRef.value?.$el?.$children?.length > 0) {
    discountTipsRefIsWrapped.value = isWrapped(discountTipsRef.value?.$el)
  }
}

// 检测是否文本溢出设置字号
const setTextOverflow = () => {
  props.isTextOverflow(paymentNameRef.value, () => {
    paymentNameRef.value?.classList?.add('mini-title')
  })
  props.isTextOverflow(btnRef.value, () => {
    btnRef.value?.classList?.add('mini-title')
  })
}

const handlePaymentOptionsOper = data => {
  emits('payment-options-oper', data)
}

// 检测是否换行
function checkIfWrapped(container) {
  if (!container) return
  const items = container?.children
  if (!items) return
  const firstItemTop = items?.[0]?.offsetTop
  for (let i = 1; i < items.length; i++) {
    if (items[i].offsetTop !== firstItemTop) {
      container?.classList?.add('wrapped-left')
      return // 发现换行
    }
  }
  container?.classList?.remove('wrapped-left')
}

const showSelectDrawer = () => {
  emits('show-select-drawer')
}

const handleTogglePpgaType = () => {
  emits('handle-toggle-ppga-type')
}

// const handleToggleAfterPayType = () => {
//   emits('handle-toggle-afterpay-type')
// }

const handleToggleSignupType = () => {
  emits('handle-toggle-signup-type', currentPayment.value?.code)
}

onMounted(() => {
  setTextOverflow()
})
</script>

<style lang="less" scoped>
.footer-had-pay {
  padding: 16/75rem 24/75rem;

  .pay-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pay-left {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: calc(100% - 2.35rem);
  }

  .pay-right {
    margin-left: 20/75rem;
  }

  .payment-src {
    width: 60/75rem;
    height: 40/75rem;
    margin-right: 12/75rem;
  }

  .pay-name {
    color: #222222;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
    max-width: calc(100% - 0.96rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8/75rem;
  }

  .change-btn {
    max-width: 88 * 2/75rem;
    height: 46/75rem;
    border-radius: 22px;
    border: 1px black solid;
    display: flex;
    align-items: center;
    justify-content: center;

    .change-btn-text {
      display: inline-block;
      padding: 0 14/75rem;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      color: black;
      font-size: 12px;
      font-family: SF Pro;
      font-weight: 510;
      word-wrap: break-word;
    }
  }

  .mini-title {
    font-size: 10px;
  }

  .wrapped-left {
    .pay-img {
      margin-bottom: 8/75rem;
    }
  }

  .division {
    margin: 0 8/75rem;
  }

  .discount-tips-wrapped {
    margin-top: 8/75rem;
  }
}
</style>
