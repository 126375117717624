<template>
  <div
    v-expose="{
      id:'expose_unusedbenefits.page_checkout:simple',
      data: {
        benefits_type: resultTips.curShowBenefitsType // 展示了气泡就上报
      }
    }"
    v-tap="{
      id: 'click_unusedbenefits.page_checkout:simple',
      data: {
        benefits_type: resultTips.curShowBenefitsType,
      }
    }"
    class="coupon-auto-tips__wrap"
    @click="handleClick"
  >
    <div
      class="tip-left"
      v-html="resultTips.htmlstr"
    >
    </div>
    <div class="tip-right">
      <i class="icon suiiconfont sui_icon_viewmore_14px_2"></i>
    </div>
  </div>
</template>

<script>



export default {
  name: 'UnusedBenefitTips',
  props: {
    resultTips: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    setTimeout(() => {
      // 6s 消失
      this.$emit('click-hiden-benefit')
      
    }, 6000)
    window.addEventListener('scroll', this.scrollTo)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollTo)
  },
  methods: {
    scrollTo(){

      window.requestAnimationFrame(() => {

        const offsetHigh = window.innerHeight - 230
        const elementRectInView = (selector) => {
          const elementRect = document.querySelector(selector)
          if(!elementRect) {
            return {
              isInView: false,
              rect: {}
            }
          }
          const rect = elementRect.getBoundingClientRect()
          const isInView = (rect.top >= 0 &&
          rect.left >= 0 &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth))
          return {
            isInView,
            rect
          }
        }
        if(this.resultTips.curShowBenefitsType === 'UnusedPoints'){
          const pointEl = elementRectInView('.use-coupon')  // document.querySelector('.use-coupon').getBoundingClientRect()
          if(pointEl.isInView && pointEl.rect.top < offsetHigh){

            this.$emit('click-hiden-benefit')
          }
        }
      
        if(this.resultTips.curShowBenefitsType === 'SHEINClubPromotion'){
          const sheinClubEl = elementRectInView('.checkout-prime-new__wrap')
          
          if(sheinClubEl.isInView && sheinClubEl.rect.top < offsetHigh){
            this.$emit('click-hiden-benefit')
          }
        }
        if(this.resultTips.curShowBenefitsType === 'SaverPromotion'){
          const saverPromotionEl = elementRectInView('.checkout-xtra__wrap')
          if(saverPromotionEl.isInView && saverPromotionEl.rect.top < offsetHigh){
            this.$emit('click-hiden-benefit')
          }
        }
        if(this.resultTips.curShowBenefitsType === 'PaymentInstallment'){

          const paymentInstallmentEl = elementRectInView('.mcheo-card')
          if(paymentInstallmentEl.isInView && paymentInstallmentEl.rect.top < offsetHigh){

            this.$emit('click-hiden-benefit')
          }

        }
      })
    },
    // 上报自动使用指定运输方式运费券
    //
    handleClick(){
      this.$emit('click-benefit')
    }
  },
  
}
</script>

  <style lang="less" scoped>
  .coupon-auto-tips_toast {
    width: 24/37.5rem;
    height: 16/37.5rem;
    margin-left: 4/37.5rem;
  }
  .coupon-auto-tips__wrap {
    position: absolute;
    top: -20/75rem;
    transform: translateY(-100%);
    background-color: rgba(0,0,0,.8);
    width: 702/75rem;
    height: auto;
    padding: 24/75rem;
    border-radius: 8/75rem;
    .flexbox();
    align-items: center;
    justify-content: space-between;
    color: @sui_color_white;

    .tip-right {
      .padding-l(12/75rem);
    }
  }
  </style>

