<template>
  <div class="checkout-activity-popover__promotion">
    <GoodsList 
      v-if="promotionCarts.length"
      class="j-activity-left"
      :language="language"
      :goodsList="promotionCarts"
      :isShowGoodsDicount="isShowGoodsDicount"
    />

    <Tips 
      v-if="description && description.length"
      :language="language"
      :icon="icon"
      :description="description"
      :timedown="countDownData"
      :checkout="checkout"
      :bubbleCopyWritingByAbt="bubbleCopyWritingByAbt"
      :isShowNewActivityPopverLayoutByAbt="isShowNewActivityPopverLayoutByAbt"
    />
  </div>
</template>

<script>
import GoodsList from './goods_info/GoodsList.vue'
import Tips from './Tips.vue'

import { template } from '@shein/common-function'
import { iconConfig } from './config'

export default {
  name: 'PromotionPopover',
  components: {
    GoodsList,
    Tips,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    integrityPromotionInfo: {
      type: Object,
      default: () => {}
    },
    promotionList: {
      type: Array,
      default: () => []
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    flashSaleGroupList: {
      type: Object,
      default: () => {}
    },
    bubbleCopyWritingByAbt: {
      type: Boolean,
      default: false
    },
    isShowNewActivityPopverLayoutByAbt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    carts() {
      return this.checkout?.results?.carts?.carts || []
    },
    mallCaculateInfo() {
      return this.checkout?.mall_caculate_info
    },
    promotionCountdownByAbt() {
      return this.checkout?.abtInfo?.PromotionCountdown?.param?.PromotionCountdown || 'typeA'
    },
    isSinglePromotion() {
      return this.promotionList.length <= 1
    },
    promotionCarts() {
      if (this.bubbleCopyWritingByAbt && !this.isSinglePromotion) {
        return this.carts
      }
      const promotion_id_list = this.promotionList.map(item => item.id) || []
      return this.carts.filter(item => {
        const product_promotion_info = item.product?.product_promotion_info || []
        const SamePromotion = product_promotion_info.find(promoption => promotion_id_list.includes(promoption.promotion_id))
        return !!SamePromotion
      })
    },
    // bubbleCopyWritingByAbt打开的时候，无论是单活动还是多活动，均不展示商品折扣标签
    isShowGoodsDicount() {
      return this.bubbleCopyWritingByAbt ? false : true
    },
    singlePromotionData() {
      const { name, tip, typeId, icon, flashType, promotionLogoType } = this.promotionList?.[0] || {}
     
      if (this.bubbleCopyWritingByAbt) {
        const saveSum = typeId == 29 ? this.mallCaculateInfo?.prime_deduce_price?.amountWithSymbol
          : this.mallCaculateInfo?.promotion_discount_info?.find(_ => _.typeId == +typeId)?.discount_price?.amountWithSymbol || 0 // 节省的金额，付费会员29 需要单独取  
        
        if([1, 9, 14, 15, 16, 26, 25].includes(+typeId)) {
          const saveText = [14, 15, 16].includes(+typeId) ? 
            `<em>${this.template(saveSum, this.language.SHEIN_KEY_PWA_31827)}</em>` : 
            `<em>${this.template(saveSum, this.language.SHEIN_KEY_PWA_31810)}</em>`
           
          return {
            name, typeId, type: 'decrease', tip: this.template(saveText, this.language.SHEIN_KEY_PWA_31811)
          }
        }
        if (typeId == 32) {
          return {
            name, typeId, type: 'decrease', tip: this.template(`<em>${name}</em>`, this.language.SHEIN_KEY_PWA_29170)
          }
        }
        if ([10, 11, 8, 3, 12, 29, 30, 31].includes(+typeId)) {
          let activityName = name
          let type = 'decrease'

          if (typeId == 31) {
            if(promotionLogoType == 3) {
              activityName = this.language.SHEIN_KEY_PWA_31804
            } else if(promotionLogoType != 1 && promotionLogoType != 3) {
              activityName = this.language.SHEIN_KEY_PWA_27511
            } else {
              activityName = this.language.SHEIN_KEY_PWA_26186
            } 
          }
          
          if (typeId == 10 && flashType == 1) {
            type = 'flash'
            activityName = `<span class="flash">${activityName}</span>`
          }
          
          const activityNameAndPrice = `<em>${this.template(saveSum, activityName, this.language.SHEIN_KEY_PWA_31813)}</em>`
          return {
            name: activityName, typeId, type, tip: this.template(activityNameAndPrice, this.language.SHEIN_KEY_PWA_31811)
          }
        }
      }

      return { type: icon, tip, typeId, name }
    },
    icon() {
      if(this.isSinglePromotion) {
        const type = this.singlePromotionData?.type || ''
        return type ? iconConfig[type] : {}
      } else {
        return {}
      }
    },
    description() {
      if(this.isSinglePromotion) {
        return this.singlePromotionData?.tip || ''
      } else {
        let discount = ''
        if (this.bubbleCopyWritingByAbt) {
          discount = this.mallCaculateInfo?.saved_total_price?.amountWithSymbol
        } else {
          discount = this.checkout.results?.carts?.cartInfo?.total_discount_price?.amountWithSymbol
        }
        return this.template(`<em>${discount}</em>`, this.language.SHEIN_KEY_PWA_29171)
      }
    },
    // 多商品时取最近的倒计时
    countDownData () {
      if(!this.isSinglePromotion || !this.promotionCarts.length) return ''
      
      const endTimeList = []
      const timeThreshold = (this.checkout?.CHECKOUT_ACTIVITY_TIME_THRESHOLD_VALUE || 48) * 60 * 60 * 1000 // 48小时的阈值

      this.promotionCarts?.forEach(item => {
        if(item.promotionTypeIds?.some(i => [3, 8, 11].includes(i)) || (item.promotionTypeIds?.includes(10) &&  [1, 4, 5].includes(Number(item.flashType)))){
          let promotion = item.product.product_promotion_info.find(_item => [3, 8, 10, 11].includes(+_item.type_id))

          if(promotion) {
            let targetPromotion = this.integrityPromotionInfo?.[promotion.promotion_id]
            if(targetPromotion?.is_count_down){
              let endTime = targetPromotion.end_time
              
              if(['typeA', 'typeB'].includes(this.promotionCountdownByAbt) && !!endTime) {
                if (endTime.toString().length < 13) {
                  endTime *= 1000
                }
                // 剩余的时间 - 当前的时间戳 的结果与时间阈值进行对比
                const differenceInMs = endTime - new Date().getTime()
                if (differenceInMs > 0 && differenceInMs <= timeThreshold) {
                  endTimeList.push(+endTime)
                } else {
                  // 活动剩余时间 > 阈值，在typeA 不展示倒计时，在typeB 直接不展示气泡
                  if (this.promotionCountdownByAbt == 'typeB') {
                    this.$emit('handleActivityPopoverBehavior', true)
                  }
                }                
              } else {
                endTimeList.push(+endTime)
              }
            }
          }
        }
      })

      return endTimeList.sort((a, b) => a - b)?.[0] || ''
    },
  },
  methods: {
    template,
  }
}
</script>
