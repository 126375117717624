var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"footer-had-pay"},[_c('div',{staticClass:"pay-block"},[_c('div',{ref:"leftRef",staticClass:"pay-left"},[_c('div',{staticClass:"pay-img"},[_c('img',{staticClass:"payment-src",attrs:{"src":_setup.currentPaymentImg}})]),_vm._v(" "),_c('div',{ref:"paymentNameRef",staticClass:"pay-name"},[(
            _setup.currentPayment.code === 'afterpay-card' &&
              _setup.afterPayVault.title &&
              _setup.afterPayVault.title === 'A' &&
              !_setup.signupPayVault?.[_setup.currentPayment.code]?.useOneTimePay
          )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.language.SHEIN_KEY_PWA_30872)}}):_c('span',[_vm._v(" "+_vm._s(_setup.currentPaymentName))])]),_vm._v(" "),_c(_setup.DiscountZero,{ref:"discountZeroRef",attrs:{"item":_setup.currentPayment,"language":_vm.language,"nowShowTokenPayment":_setup.nowShowTokenPayment}}),_vm._v(" "),(_setup.discountZeroRefIsWrapped)?_c('span',{staticClass:"division"},[_vm._v("\n        +\n      ")]):_vm._e(),_vm._v(" "),_c(_setup.DiscountTips,{ref:"discountTipsRef",class:[
          {
            'discount-tips-wrapped': _setup.discountTipsRefIsWrapped
          }
        ],attrs:{"checkout":_vm.checkout,"language":_vm.language,"price":_vm.price,"current-payment":_setup.currentPayment,"locals":_vm.locals,"icon-prefix":_vm.iconPrefix,"is-show-token-payment-by-abt":_vm.isShowTokenPaymentByAbt,"is-show-token-payment":_setup.isShowTokenPayment},on:{"payment-options-oper":_setup.handlePaymentOptionsOper}})],1),_vm._v(" "),_c('div',{staticClass:"pay-right"},[_c('div',{staticClass:"change-btn",on:{"click":_setup.showSelectDrawer}},[_c('span',{ref:"btnRef",staticClass:"change-btn-text"},[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_29362)+"\n        ")])])])]),_vm._v(" "),(_setup.isShowCheck)?_c(_setup.CheckPpgaType,{attrs:{"language":_vm.language,"paypal-ga-vault":_setup.paypalGaVault,"checked-label":_setup.CHECKED},on:{"toggle":_setup.handleTogglePpgaType}}):_vm._e(),_vm._v(" "),(_setup.isShowSignupCheck)?_c(_setup.CheckSignupPayType,{attrs:{"language":_vm.language,"payment-code":_setup.currentPayment.code,"vault":_setup.signupPayVault[_setup.currentPayment.code],"checked-label":_setup.SIGNUP_CHECK_TYPE.CHECKED},on:{"toggle":_setup.handleToggleSignupType}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }