<template>
  <div class="mcheo-card mcheo-payment pay-methods-list">
    <ul
      class="pay-list j-payment-method mcheo-payment__methods"
      :class="{ 'no-select-payment': paymentMethodsProps.noSelectPayment }"
    >
      <payment-options-item
        v-for="(item, idx) in paymentMethods"
        :key="`drawer-${item.code}`"
        :item="item"
        :idx="idx"
        :status="paymentMethodsProps.status"
        :checkout="paymentMethodsProps.checkout"
        :language="paymentMethodsProps.language"
        :banks-config="paymentMethodsProps.banksConfig"
        :ppgv-account-info="paymentMethodsProps.ppgvAccountInfo"
        :signup-account-info="paymentMethodsProps.signupAccountInfo"
        :not-support-cod-reason="paymentMethodsProps.notSupportCodReason"
        :payment-display-again="paymentMethodsProps.paymentDisplayAgain"
        :paypal-ga-vault="paymentMethodsProps.paypalGaVault"
        :afterPayVault="paymentMethodsProps.afterPayVault"
        :signup-pay-vault="paymentMethodsProps.signupPayVault"
        :payment-icon-prefix="paymentIconPrefix"
        :google-valid="paymentMethodsProps.googleValid"
        :price="paymentMethodsProps.price"
        :installment-info="installmentInfo"
        :payment-option="paymentMethodsProps.paymentOption"
        :card-bin-discount-desc="cardBinDiscountDesc"
        :need-pay-inline-payment-methods="
          paymentMethodsProps.needPayInlinePaymentMethods
        "
        :cod-display="paymentMethodsProps.codDisplay"
        :show-widget-form="paymentMethodsProps.showWidgetForm"
        :locals="paymentMethodsProps.locals"
        :default-select-token-pay="defaultSelectTokenPay"
        :total-price-amount="paymentMethodsProps.totalPriceAmount"
        @choosePayment="selectPayment"
        @showMaskTips="paymentMethodsProps.showMaskTips"
        @startSelectBank="paymentMethodsProps.handleStartSelectBank"
        @handleTogglePpgaType="handleTogglePpgaType"
        @handleToggleAfterPayType="handleToggleAfterPayType"
        @handleToggleSignupType="handleToggleSignupType"
        @handlePaymentOptionsOper="handlePaymentOptionsOper"
        @clickPaypalGaType="clickPaypalGaType"
        @showTokenListDrawer="showTokenListDrawer"
        @changeDefaultSelectTokenPay="changeDefaultSelectTokenPay"
      />
    </ul>

    <ClientOnly>
      <choose-token-pay 
        v-model="isShowTokenPayDrawer"
        :checkout="paymentMethodsProps.checkout"
        :language="language"
        :default-select-token-pay="defaultSelectTokenPay"
        :logo-list="paymentLogoList"
        @changeDefaultSelectTokenPay="changeDefaultSelectTokenPay"
      />
    </ClientOnly>
    <ClientOnly>
      <s-drawer
        class="ppgv-type-drawer"
        :visible.sync="paymentMethodsProps.paypalGaVault.tipShow"
        :append-to-body="true"
      >
        <template slot="top">
          {{ language.SHEIN_KEY_PWA_19686 }}
        </template>
        <div>
          <p class="type-des">
            {{ language.SHEIN_KEY_PWA_19687 }}
          </p>
          <div
            class="type-radio"
          >
            <s-radio
              v-model="paymentMethodsProps.paypalGaVault.radioType"
              class="type-item"
              :label="PPGA_CHECK_TYPE.CHECKED"
            >
              <!-- eslint-disable -->
              {{
                paymentMethodsProps.ppgvAccountInfo && paymentMethodsProps.ppgvAccountInfo.signUpEmail
                  ? template(
                      paymentMethodsProps.ppgvAccountInfo.signUpEmail,
                      language.SHEIN_KEY_PWA_19685
                    )
                  : language.SHEIN_KEY_PWA_19684
              }}
              <!-- eslint-enable -->
            </s-radio>
            <div @click="clickPaypalSelect">
              <s-radio
                :value="paymentMethodsProps.paypalGaVault.radioType"
                class="type-item type-item-last"
                :label="PPGA_CHECK_TYPE.UNCHECKED"
              >
                {{ language.SHEIN_KEY_PWA_19683 }}
              </s-radio>
            </div>
          </div>

          <div class="confirm-btn">
            <s-button
              width="100%"
              :type="['primary']"
              @click="confirmPPGVType"
            >
              {{ language.SHEIN_KEY_PWA_19692 }}
            </s-button>
          </div>
        </div>
      </s-drawer>
    </ClientOnly>

    <SignupPayOneTimePayDrawer
      :language="language"
      :vault="paymentMethodsProps.signupPayVault[signupDrawerConfig?.paymentCode]"
      :account-info="paymentMethodsProps.signupAccountInfo[signupDrawerConfig?.paymentCode]"
      :paymentCode="signupDrawerConfig?.paymentCode"
      :show="signupDrawerConfig?.show"
      @confirm="handleToggleOneTimePay"
    />
  </div>
</template>

<script setup>
// components
import paymentOptionsItem from '../payment_options_item.vue'
import ChooseTokenPay from 'public/src/pages/checkout/page_tpls/choose_token_pay.vue'
import ClientOnly from 'vue-client-only'
import { PPGA_CHECK_TYPE } from 'public/src/pages/common/constants'
import { Toast as $toast } from '@shein/sui-mobile'
// utils
import { computed, ref, watch, defineEmits, defineAsyncComponent } from 'vue'
import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import {
  useStore, useMapMutations
} from 'public/src/pages/checkout/hooks/index.js'

const SignupPayOneTimePayDrawer = defineAsyncComponent(() => import('public/src/pages/checkout/vue_tpls/SignupPayOneTimePayDrawer.vue'))

const props = defineProps({
  paymentMethods: {
    type: Array,
    default: () => {
      ;[]
    }
  },
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  show: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['changePay', 'handle-toggle-ppga-type'])

const store = useStore()
const { assignState } = useMapMutations(['assignState'])

const nowOpts = ref({})
const installmentInfo = ref({}) // 分期信息
const installmentInfoCache = ref({}) // 分期信息缓存
const installmentInfoCacheKey = ref('')
const requesting = ref(false)
const isShowTokenPayDrawer = ref(false)

const mall_caculate_info = computed(() => {
  return (
    props?.paymentMethodsProps?.checkout.mall_caculate_info ||
    props?.paymentMethodsProps?.checkout.caculate_info ||
    {}
  )
})

const paymentIconPrefix = computed(() => {
  return props.paymentMethodsProps?.iconPrefix
    ? props.paymentMethodsProps?.iconPrefix
    : 'suiiconfont'
})

const cardBinDiscountDesc = computed(() => {
  // 当命中多个卡bin优惠，不区分命中的类型
  if (
    props?.paymentMethodsProps?.checkout?.results?.bin_discount_list?.length > 1
  ) {
    const lengthObj = {
      2: props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_26144,
      3: props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_26145
    }

    return template(
      ...props?.paymentMethodsProps?.checkout?.results?.bin_discount_list?.map(
        item => item?.bank_name
      ),
      lengthObj[
        props?.paymentMethodsProps?.checkout?.results?.bin_discount_list?.length
      ]
    )
  }

  const {
    bank_name = '',
    discount_amount = {},
    discount_percentage = 0,
    discount_type = 0,
    threshold_amount = {},
    max_discount_amount = {},
    max_random_amount = {},
    min_random_amount = {}
  } = props?.paymentMethodsProps?.checkout?.results?.bin_discount_list?.[0] ||
  props?.paymentMethodsProps?.checkout?.results?.bin_discount_info ||
  {}
  if (discount_type === 1) {
    return template(
      discount_amount?.amountWithSymbol,
      threshold_amount?.amountWithSymbol,
      bank_name || '',
      props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_24447
    )
  }
  if (discount_type === 2) {
    const maxDiscountAmount = max_discount_amount?.amount
    const thresholdAmount = threshold_amount?.amount
    // 折扣--无门槛
    if (thresholdAmount == 0) {
      return template(
        maxDiscountAmount > 0
          ? max_discount_amount.amountWithSymbol
          : discount_percentage || '',
        bank_name || '',
        maxDiscountAmount > 0
          ? props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_24525
          : props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_24526
      )
    }
    return template(
      maxDiscountAmount > 0
        ? max_discount_amount.amountWithSymbol
        : discount_percentage || '',
      bank_name || '',
      threshold_amount?.amountWithSymbol,
      maxDiscountAmount > 0
        ? props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_24453
        : props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_24448
    )
  }
  // 随机立减
  if (discount_type == 3 && isShowBinRandomDiscountABT.value) {
    const thresholdAmount = threshold_amount?.amount
    // 折扣--无门槛
    if (thresholdAmount == 0) {
      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        bank_name || '',
        bank_name
          ? props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_28692
          : props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_24866
      )
    }
    return template(
      min_random_amount?.amountWithSymbol,
      max_random_amount?.amountWithSymbol,
      threshold_amount?.amountWithSymbol,
      bank_name || '',
      bank_name
        ? props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_28691
        : props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_24937
    )
  }
  return ''
})

const installmentSelectBin = computed(() => {
  return store?.state?.checkout?.installmentSelectBin
})

const defaultSelectTokenPay = computed(() => {
  return store?.state?.checkout?.defaultSelectTokenPay
})

const productAutoInfo = computed(() => {
  return store?.getters?.['checkout/productAutoInfo']
})

const isShowBinRandomDiscountABT = computed(() => {
  const { BinRandomDiscount = {} } =
    props?.paymentMethodsProps?.checkout?.abtInfo || {}
  return BinRandomDiscount?.param?.BinRandomShow === 'Show'
})

const signupDrawerConfig = computed(() => {
  return props?.paymentMethodsProps?.signupDrawerConfig || {}
})

const paymentLogoList = computed(() => {
  return store?.state?.checkout?.paymentLogoInfo?.info?.logoList || []
})

watch(
  () => props?.paymentMethodsProps?.checkout?.mall_caculate_info,
  () => {
    if (typeof window === 'undefined') return
    if (props.paymentMethods.length) {
      if (props?.paymentMethodsProps?.checkoutScene === 'buyprime') return
      getInstallmentInfo && getInstallmentInfo()
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => props.paymentMethodsProps?.status?.cardPay,
  () => {
    if (typeof window === 'undefined') return
    const nowPay = props.paymentMethods?.find(
      item => item?.code === props.paymentMethodsProps?.status?.cardPay
    )
    if (nowPay) {
      emits('changePay', nowPay)
    }
  },
  {
    immediate: true
  }
)

watch(() => installmentSelectBin.value, () => {
  fetchInstallmentInfo()
}, { immediate: true })

const selectPayment = opts => {
  if (opts?.item?.enabled === 0) return // 置灰情况下不能点击
  nowOpts.value = opts

  if (
    props.paymentMethodsProps.needPayInlinePaymentMethods?.includes(
      opts.code
    ) &&
    opts.code.indexOf('klarna') > -1
  ) {
    props.paymentMethodsProps?.klarna(
      opts.code,
      `#${opts.code}-dialog-payments-container`
    )
  }

  choosePayment()
}

const handleTogglePpgaType = () => {
  emits('handle-toggle-ppga-type')
}

const handleToggleAfterPayType = () => {
  emits('handle-toggle-afterpay-Type')
}

const handleToggleSignupType = (paymentCode) => {
  emits('handle-toggle-signup-type', paymentCode)
}

const handleToggleOneTimePay = (data) => {
  props?.paymentMethodsProps?.confirmSignupOneTimePay?.(data)
}

const clickPaypalSelect = (event) => {
  const primeActivityType = props?.paymentMethodsProps?.checkout?.results?.primeActivityType
  const { currentProduct } = productAutoInfo?.value || {}
  // const tostLanguage = primeActivityType == 1 ? props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_31535 : template(
  //   props.paymentMethodsProps?.paypalGaVault.isPayListDialog ? props.paymentMethodsProps?.paypalGaVault?.product_name_language : currentProduct?.product_name_language, 
  //   props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_29576
  // )
  const tostLanguage = props?.paymentMethodsProps?.language?.SHEIN_KEY_PWA_31535
  if (primeActivityType == 1 || currentProduct?.product_activity_type == 1 || props.paymentMethodsProps?.paypalGaVault.isPayListDialog) {
    event.preventDefault()
    return $toast( tostLanguage )
  } else {
    props.paymentMethodsProps.paypalGaVault.radioType = PPGA_CHECK_TYPE.UNCHECKED
  }
}

const confirmPPGVType = () => {
  emits('confirm-ppgv-type', true)
}

const resetInstallmentInfoData = () => {
  requesting.value = false
  installmentInfo.value = {}
}
const setInstallmentInfo = (res, cacheKey) => {
  if (res && res.code == 0) {
    if (
      res.info &&
      res.info.front_show_list &&
      res.info.front_show_list.length
    ) {
      let front_list_items = {}
      res.info.front_show_list.forEach(item => {
        front_list_items[item.payment_code] = item
      })
      res.info.front_list_items = front_list_items
    }

    installmentInfo.value = res?.info || {}
    installmentInfoCache.value[cacheKey] = res.info || {}
    installmentInfoCacheKey.value = cacheKey
  } else {
    installmentInfo.value = {}
  }
  requesting.value = false
}
function getInstallmentInfo(opt) {
  if (requesting.value) return
  let data = {}
  if (opt) {
    data = opt
  } else {
    data = {
      country: props?.paymentMethodsProps?.checkout?.default_address?.value,
      amount: mall_caculate_info.value?.grandTotalPrice?.amount,
      currency: gbCommonInfo.currency
    }
  }
  const cacheKey = data.country + '_' + data.currency + '_' + data.amount
  if (installmentInfoCache.value?.[cacheKey]) {
    if (installmentInfoCacheKey.value != cacheKey) {
      installmentInfoCacheKey.value = cacheKey
      installmentInfo.value = installmentInfoCache.value?.[cacheKey]
    }
  } else {
    requesting.value = true
    schttp({
      method: 'POST',
      url: '/api/checkout/installmentDetail/get',
      data
    })
      .then(res => {
        setInstallmentInfo(res, cacheKey)
      })
      .catch(() => {
        resetInstallmentInfoData()
      })
  }
}

const handlePaymentOptionsOper = (type = '', data = {}) => {
  if (!type) return
  props.paymentMethodsProps.handlePaymentOptionsOper({ type, data })
}

//展示paypal ga 签约切换弹窗
const clickPaypalGaType = (code) => {
  if (code === 'PayPal-GApaypal') {
    props.paymentMethodsProps.showPaypalGaType()
    props.paymentMethodsProps.paypalGaVault.product_name_language =
      props.payMethodsListDrawerHeaderData?.product_name_language
  } else {
    props.paymentMethodsProps.handleClickSignupOneTimeArrow(code)
  }
}

const paymentDisplay = code => {
  if (
    props.paymentMethodsProps?.paymentDisplayAgain &&
    typeof props.paymentMethodsProps?.paymentDisplayAgain == 'function'
  ) {
    return props.paymentMethodsProps?.paymentDisplayAgain(code)
  } else {
    return true
  }
}

const paymentMethodLimitInfo = computed(() => {
  return (
    props?.paymentMethodsProps?.mall_caculate_info?.paymentMethodLimitInfo || {}
  )
})

const showPriceLimit = code => {
  let txt = ''
  if (mall_caculate_info.value?.paymentMethodAmountLimitInfo?.[code]) {
    const item = mall_caculate_info.value?.paymentMethodAmountLimitInfo?.[code]
    const { maxAmount, minAmount } = item
    if (minAmount && maxAmount) {
      txt = template(
        minAmount,
        maxAmount,
        props.paymentMethodsProps?.language?.SHEIN_KEY_PWA_16733
      )
    } else if (!minAmount && maxAmount) {
      txt = template(
        maxAmount,
        props.paymentMethodsProps?.language?.SHEIN_KEY_PWA_16753
      )
    } else if (minAmount && !maxAmount) {
      txt = template(
        minAmount,
        props.paymentMethodsProps?.language?.SHEIN_KEY_PWA_16734
      )
    }
  }
  txt = txt.replace(/\s/g, ' ')
  return txt
}

// 置灰文案
const getPaymentDes = item => {
  let des = ''
  // 如果支付可用
  if (item?.show_description && item?.enabled == 1 && item?.description) {
    des = item.description

    // 如果支付不可用，或者cod不可用时
  } else if (item?.enabled != 1) {
    // 按优先级展示置灰原因，越往下，优先级越高

    // 业务配置置灰文案
    des = item?.gray_description || des

    // 金额限制
    if (paymentMethodLimitInfo.value[item?.code] == 0) {
      des = showPriceLimit(item?.code)
    }

    // cod不可用原因
    if (item.code == 'cod') {
      des = props.paymentMethodsProps?.notSupportCodReason || des
    }
  }

  //如果有店铺限制置灰，优先展示置灰文案
  if (item.isStoreDisabled) {
    des = props.paymentMethodsProps.language.SHEIN_KEY_PWA_20331
  }

  // 本地采销全量置灰优先级最高
  if (item.isAllBusiness) des = item.gray_description

  if (item.gray_reason == 1) {
    //业务配置置灰文案
    des = props.paymentMethodsProps.language.SHEIN_KEY_PWA_24508 || ''
  }

  // 03 本地自营置灰 => wiki.pageId=1176265937
  if (item.gray_reason == 3) {
    //业务配置置灰文案
    des = props.paymentMethodsProps.language.SHEIN_KEY_PWA_26043 || 'error 03'
  }

  return des
}

const clickDes = item => {
  const des = getPaymentDes(item)
  des && props.paymentMethodsProps.showMaskTips(des)
}

const choosePayment = () => {
  const { item, isPaymentSuggestionTriggerClick } = nowOpts.value
  if (item?.isAllBusiness)
    return props.paymentMethodsProps?.showMaskTips(item?.gray_description)

  // 币种限制
  if (!paymentDisplay(item.code)) {
    props.paymentMethodsProps?.showMaskTips(
      props.paymentMethodsProps?.language?.SHEIN_KEY_PWA_17728
    )
    return
  }
  if (item && item?.enabled != 1) {
    clickDes(item)
    return
  }
  props.paymentMethodsProps.clickPayment({
    id: item.id,
    code: item.code,
    item: item,
    isPaymentSuggestionTriggerClick,
    afterPayVault: props.paymentMethodsProps.afterPayVault
  })
}

const showTokenListDrawer = () => {
  isShowTokenPayDrawer.value = true
}

const changeDefaultSelectTokenPay = item => {
  store.commit('checkout/updateDefaultSelectTokenPay', item)
}

async function fetchInstallmentInfo() {
  const isCanGetInstallment = props?.paymentMethodsProps?.checkout?.default_address?.value?.length > 0 && props?.paymentMethodsProps?.checkout?.mall_caculate_info?.orderCurrency?.code?.length > 0
  if (!isCanGetInstallment) return
  const amount = props?.paymentMethodsProps?.checkout?.mall_caculate_info?.grandTotalPrice?.amount ||
    props?.paymentMethodsProps?.checkout?.results?.carts?.cartInfo?.totalPrice?.amount
  const res = await schttp({
    url: '/api/checkout/installmentInfo/get',
    params: {
      paymentCode: 'routepay-cardinstallment',
      countryCode: props?.paymentMethodsProps?.checkout?.default_address?.value || '', // 国家
      orderCurrency: props?.paymentMethodsProps?.checkout?.mall_caculate_info?.orderCurrency?.code, // 币种
      orderAmount: amount, // 金额
      cardBin: installmentSelectBin.value || ''
    }
  })

  if (res.code === '0') {
    const tempInstallmentList = res.info?.installments || []
    const recommend_installments = res.info?.recommend_installments
    const recommend_reason = res.info?.recommend_reason
    if ((recommend_installments ?? null) !== null) {
      tempInstallmentList.forEach(item => {
        item.recommend_installments = recommend_installments
        item.recommend_reason = recommend_reason
      })
    }
    assignState({
      binInstallmentListInfo: res.info?.installments || []
    })
  }
}
</script>

<style lang="less" scoped>
.mcheo-payment {
  margin-top: unset;
  max-height: calc(80vh - 6.6rem);
  overflow-y: auto; /* 当内容超出时垂直方向上滚动 */
  overflow-x: hidden; /* 如果不需要水平滚动可以隐藏 */
}

.pay-methods-list {
  #paypal-bnpl-message{
    margin-top: 12/75rem;
  }
}
</style>
