<template>
  <div ref="couponOptions">
    <AssetsListEffiency 
      :checkCouponPrice="checkCouponPrice"
      :isSiteMallList="isSiteMallList"
      :riskStr="riskStr"
      class="assets-list__wrap"
      @goWalletPage="goWalletPage"
    />
    <ClientOnly>
      <div v-if="asyncComponentLoadedStatus.pointIsLoaded || asyncComponentLoadedStatus.WalletIsLoaded">
        <!-- wallet, point滑出层 -->
        <input-panel
          v-if="asyncComponentLoadedStatus.WalletIsLoaded"
          type="wallet"
          :max-data-with-symbol="checkout && checkout.mall_caculate_info && checkout.mall_caculate_info.wallet.subtractPrice.amountWithSymbol"
          :max-data="checkout && checkout.mall_caculate_info && checkout.mall_caculate_info.wallet.subtractPrice.amount"
          @apply="applyWallet"
        />

        <input-panel
          v-if="asyncComponentLoadedStatus.pointIsLoaded"
          type="point"
          :max-data="maxAvailablePoint"
          @apply="applyPoints"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script>
/* globals shem_caculate_data */
import Vue from 'vue'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { template as _commonTemplate } from '@shein/common-function'
import { Alert } from '@shein/sui-mobile'
import AssetsListEffiency from './assets_list_effiency.vue'
import { fetchWalletInfoApi } from 'public/src/services/api/pay/checkout.js'

Vue.use(Alert)


export default {
  name: 'CouponOptions',
  components: {
    AssetsListEffiency,
    inputPanel: () => import(/* webpackChunkName: "checkout-input-panel" */'../vue_tpls/input_panel.vue')
  },
  props: {
    checkCouponPrice: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      riskStr: 1,
    }
  },
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
      'price',
      'asyncComponentLoadedStatus',
      'locals',
    ]),
    ...mapGetters('checkout', [
      'isShopType'
    ]),
    IS_RW() {
      return this.locals?.IS_RW ?? gbCommonInfo.IS_RW
    },
    maxAvailablePoint() {
      return this.IS_RW ? this.checkout.mall_caculate_info?.maxAvailablePoint : this.checkout.mall_caculate_info?.maxAvailablePoint2
    },
    // 站点mall 多mall站样式按多mall来. 
    isSiteMallList () { 
      return this.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
    },
  },
  watch: {
    'checkout.mall_caculate_info': function () {
      this.updateRiskStr()
    },
  },
  beforeMount () {
    this.getWalletInfo()
  },
  mounted () {
    this.updateRiskStr()
  },
  methods: {
    ...mapMutations('checkout', ['assignState', 'changeParamsStatus']),
    ...mapActions('checkout', ['mallCacuTotal']),
    template: _commonTemplate,
    updateRiskStr () {
      let riskStr = 1
      if (this.checkout?.mall_caculate_info?.wallet?.risk_res_info?.risk_decision == 0) {
        riskStr = this.$parent.$refs.mainLogin && this.$parent.$refs.mainLogin.updateRiskStr(this.checkout.mall_caculate_info.wallet.risk_res_info) || 1
      }
      this.riskStr = riskStr
    },
    goWalletPage: function (isFolded) {
      // 如果是风控用户（爆破黑名单），则展示验证弹窗
      if (this.riskStr == 2) {
        this.$parent.$refs.mainLogin.dialogUpdateInfo({
          scene: 'view_wallet_balance',
          info: this.checkout.mall_caculate_info.wallet.risk_res_info
        })
        this.$parent.showRiskDialog = true
        return
      }

      this.changeParamsStatus({ type: 'pageStatus', params: { showWalletInputPanel: true } })
      this.assignState({
        alreadyShowWalletPanel: true,
      })

      daEventCenter.triggerNotice({
        daId: '1-11-1-132',
        extraData: {
          is_choosed: Number(this.price?.walletPrice?.amount) > 0 ? 1 : 0,
          is_folded: isFolded,
        },
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-133',
        extraData: {
          button_included: +this.price?.walletPrice?.amount > 0 && this.price?.walletPrice?.amountWithSymbol ? 'remove' : 'apply'
        }
      })
    },
    applyPoints: function (opt) {
      if (!opt) return

      const _this = this

      this.changeParamsStatus({ type: 'codConfirm', params: { applyType: 'point' } })

      this.changeParamsStatus({
        type: 'point',
        params: {
          costShow: false,
          err: '',
          apply: true
        }
      })

      shem_caculate_data.auto_use_point = 0
      shem_caculate_data.auto_use_point_threshold = ''

      this.$nextTick(function () {
        shem_caculate_data.points = opt.data

        _this.mallCacuTotal({
          opt: shem_caculate_data,
          type: 'points',
          cb: function (res) {
            if (res.code == 0) {
              _this.changeParamsStatus({
                type: 'point',
                params: {
                  costShow: !!+shem_caculate_data.points,
                  success: true
                }
              })

              if (!opt.isRemove) {
                daEventCenter.triggerNotice({
                  daId: '1-11-1-140',
                  extraData: {
                    result: '1'
                  }
                })
              }

              if (shem_caculate_data.points > 0 && res.info.notFreeShippingTips == 0) {
                _this.$toast(_this.language.SHEIN_KEY_PWA_16456)
              }
            } else {
              if(!opt.isRemove) {
                daEventCenter.triggerNotice({
                  daId: '1-11-1-140',
                  extraData: {
                    result: '0'
                  }
                })
              }
            }
          }
        })
      })
    },
    applyWallet: function (opt) {
      if (!opt) return

      const _this = this

      this.changeParamsStatus({
        type: 'wallet',
        params: {
          costShow: false,
          err: '',
          apply: true
        }
      })

      if (opt.data) {
        shem_caculate_data.use_wallet = 1
        shem_caculate_data.use_wallet_amount = (+opt.data >= +this.checkout.mall_caculate_info.wallet.subtractPrice.amount) ? this.checkout.mall_caculate_info.wallet.subtractPrice.amount : opt.data
      } else {
        shem_caculate_data.use_wallet = 0
        shem_caculate_data.use_wallet_amount = ''
      }

      _this.mallCacuTotal({
        opt: shem_caculate_data,
        cb: function (res) {
          if (res.code == 0) {
            _this.changeParamsStatus({
              type: 'wallet',
              params: {
                costShow: !!+shem_caculate_data.use_wallet,
                success: true
              }
            })

            if (shem_caculate_data.use_wallet) {
              _this.$toast(_this.language.SHEIN_KEY_PWA_16490)
            }

            if (!opt.isRemove && res.info && res.info.walletPrice) {
              daEventCenter.triggerNotice({
                daId: '1-11-1-143',
                extraData: {
                  result: '1'
                }
              })
            }
          } else {
            if(!opt.isRemove) {
              daEventCenter.triggerNotice({
                daId: '1-11-1-143',
                extraData: {
                  result: '0'
                }
              })
            }
          }
        }
      })
    },
    // 获取钱包冻结信息
    getWalletInfo(){
      
      // schttp({
      //   url: '/api/checkout/walletInfo/get'
      // })
      fetchWalletInfoApi().then(res => {
        this.changeParamsStatus({ type: 'walletFreez', params: { showReason: true } })

        let { info = {} } = res || {}
        let { data: tradeInfo = [] } = info || {}
        if (tradeInfo && tradeInfo.length) {
          this.changeParamsStatus({ type: 'walletFreez', params: { list: tradeInfo } })
          let freezList = tradeInfo.filter(item => item.second_type == '2200' && item.trade_count > 0)
          let unpaidList = tradeInfo.filter(item => item.second_type == '2100' && item.trade_count > 0)
          if (freezList.length) {
            this.changeParamsStatus({ type: 'walletFreez', params: { restTxt: true } })
          }
          if (unpaidList.length) {
            this.changeParamsStatus({ type: 'walletFreez', params: { unpaidTxt: true } })
          }
        }
      })
    }
  }
}
</script>

<style lang="less">
.price-coupon-diff {
	width: 100%;
}
.price-diff-tip {
	background: #FFF7F0;
	padding: 0.21333rem;
	.tip-red {
		color: #FA6338;
	}
}
.wallet-self {
	color: #767676;
	background: #F6F6F6;
	padding: 0.05333rem 0.10666rem;
}
.mshe-flexbetween {
    display: flex;
    justify-content: space-between;
}
.mcheo-icon-grep {
	color: @sui_color_gray_light1;
}
.assets-list__wrap {
	background: #fff;
  margin-top: 16/75rem;
	.padding-l(.32rem);
	li {
		padding: .2rem;
		.padding-r(.32rem);
		.padding-l(0);
		.align-center();
		.border-dpr(border-bottom,2px,#e5e5e5);
		&.stand-coupon {
			padding: 0;
			.padding-r(.4rem);
			.padding-l(0rem);
		}
		&.giftcard {
			height: 1.28rem;
		}
		&.use-coupon {
			min-height: 1.28rem;
		}
    .use-coupon-right{
      .txt-r();
      .padding-r(.6rem);
      .use-total {
        color: #767676;
      }
      .use-red {
        font-weight: bold;
        color: @sui_color_discount;
      }
      .coupon-prime {
        color: @sui_color_club_rosegold_dark2;
      }
    }
		.stand-left {
			width: 100%;
		}
    .input-icon {
      width: 100%;
      display: table;
      .input-icon-cell {
        display: table-cell;
      }
    }
    .input-coupon {
      .flexbox();
      .align-center();
      .space-between()
    }
		.icon-pro {
			color: #999;
			font-size: 16px;
			text-decoration: none;
			.margin-l(.2rem);
		}
		.coupon-list {
			width: 100%;
			max-height: 3.8rem;
			overflow-y: scroll;
			position: absolute;
			top: 1rem;
			background-color: #fff;
			box-shadow: 0 2px 11px 0 rgba(0,0,0,0.14);
			.left(0);
			.coupon-list-item{
				color: #222;
				padding: 0.21rem 0;
				.padding-l(.21rem);
			}
		}
		.coupon-right {
			.txt-r();
			white-space: nowrap;
			.coupon-discount {
				.padding-l(.2rem);
				color: @color_green;
				font-weight: 700;
			}
			.coupon-down{
				.padding-l(.2rem);
				.font-dpr(32px);
				line-height: .8rem;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
  .checkout-assets-item {
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
