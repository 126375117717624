<template>
  <div class="page-tips">
    <div
      v-if="sfsProductsTipsVisible" 
      class="page-tips__sfs"
      @click.stop="openSfsProductsDrawer"
    >
      <span class="page-tips__sfs-label">
        {{ language.SHEIN_KEY_PWA_33962 }}
      </span>
      <span class="page-tips__sfs-text">
        {{ sfsProductsTips }}
      </span>
    </div>
    <s-alert
      v-if="showTaxHint"
      v-expose="{
        id: '1-11-1-168',
        data: { mall_code: mallCode, location: 'page' }
      }"
      v-tap="{
        id: '1-11-1-169',
        data: { mall_code: mallCode, location: 'page' }
      }"
      class="page-tips__hint"
      type="info" 
      :max-rows="1000"
      :jumpable="!isLastGoods"
      @click.native.stop="clickTaxHint"
    >
      <div v-html="taxHint"></div>
    </s-alert>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useMapState, useMapGetters, useMapMutations } from 'public/src/pages/checkout/hooks/index.js'

const props = defineProps({
  cartsInfo: {
    type: Array,
    default: () => []
  },
  mallCode: {
    type: [String, Number],
    default: '',
  },
  isLastGoods: {
    type: Boolean,
    default: false
  },
  showShoppingBagItemDetail: {
    type: Boolean,
    default: false
  }
})

const { 
  price,
  language, 
  touchRuleInfo 
} = useMapState([
  'price',
  'language', 
  'touchRuleInfo'
])
const { 
  SiteUID, 
  isShowbrTaxhint,
  CartOrderSFSVisual
} = useMapGetters([
  'SiteUID', 
  'isShowbrTaxhint',
  'CartOrderSFSVisual'
])
const { assignState, updateState } = useMapMutations(['assignState', 'updateState'])

const taxHint = computed(() => {
  const { tax = [] } = price.value || {}
  const taxList = tax.filter(item => +item.taxPrice?.amount > 0 && item.taxType == 7) || []
  // 当前不会存在配置多条的情况，默认取第一个处理
  const taxPrice = taxList[0]?.taxPrice?.amountWithSymbol || ''
  const taxHint = touchRuleInfo.value?.msg || ''

  return (!!taxList.length && taxHint) ? taxHint.replace('{br_tax_1}', taxPrice) : ''
})

const sfsProducts = computed(() => props.cartsInfo.filter(item => item.sfs_flag == 1))
// 只有一个商品且该商品为 sfs 商品
const isOnlyOneSfs = computed(() => props.cartsInfo.length === 1 && sfsProducts.value.length === 1)
const isAllSfs = computed(() => sfsProducts.value.length === props.cartsInfo.length)
const sfsProductsTipsVisible = computed(() => (
  CartOrderSFSVisual.value.visual_tabs &&
  CartOrderSFSVisual.value.visual_benefit_point &&
    !props.showShoppingBagItemDetail &&
    sfsProducts.value.length !== 0    
))
const sfsProductsTips = computed(() => {
  let tips = ''
  if (isAllSfs.value) {
    tips = language.value.SHEIN_KEY_PWA_33964
  } else {
    tips = language.value.SHEIN_KEY_PWA_33965
  }
  if (!isOnlyOneSfs.value) tips += ' >'
  return tips
})

const showTaxHint = computed(() => {
  const isBrSite = !!~SiteUID.value?.indexOf('br')

  const { match_goods = [] } = touchRuleInfo.value || {}
  const touchRuleId = match_goods?.map(item => item.id) || []
  let cartInclude =  false
  props.cartsInfo.forEach(cart => {
    if(touchRuleId.includes(cart.id)) cartInclude = true
  })

  return isShowbrTaxhint.value && isBrSite && !!taxHint.value && cartInclude
})

const openSfsProductsDrawer = () => {
  // 仅有一个 sfs 商品时不允许点击
  if (isOnlyOneSfs.value) return
  updateState({
    key: 'showSfsProductsDrawer', value: true
  })
}

const clickTaxHint = () => {
  // 结算车内最后一个商品无点击行为（非mall下）
  if(props.isLastGoods) return

  // 修改购物车状态接口无法正常获取下单页券赠品勾选状态，弹窗内不展示该商品
  const carts = props.cartsInfo.filter(item => item.promotion_type_id != '1000')
  assignState({
    taxCartsInfo: {
      list: carts,
      mallCode: props.mallCode
    },
  })
  updateState({
    key: 'showBagTax', value: true
  })
}

</script>
<style lang="less" scoped>
.page-tips {
  &__hint {
    margin: 20/75rem 24/75rem 8/75rem 0;
    padding: 20/75rem 0 20/75rem 20/75rem;
  }

  &__sfs {
    margin: 12/75rem 24/75rem 0 0;
    font-family: "SF Pro";
    font-size: 10px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp:2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  &__sfs-label {
    padding: 2/75rem 6/75rem;
    background-color: #ECFCF3;
    color: #198055;
    display: inline-block;
    line-height: 1.2;
    border-radius: 4/75rem;
    margin-right: 6/75rem;
    vertical-align: top;
  }

  &__sfs-text {
    color: #666;
    
  }
}
</style>
