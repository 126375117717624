var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-tips"},[(_setup.sfsProductsTipsVisible)?_c('div',{staticClass:"page-tips__sfs",on:{"click":function($event){$event.stopPropagation();return _setup.openSfsProductsDrawer.apply(null, arguments)}}},[_c('span',{staticClass:"page-tips__sfs-label"},[_vm._v("\n      "+_vm._s(_setup.language.SHEIN_KEY_PWA_33962)+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"page-tips__sfs-text"},[_vm._v("\n      "+_vm._s(_setup.sfsProductsTips)+"\n    ")])]):_vm._e(),_vm._v(" "),(_setup.showTaxHint)?_c('s-alert',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-11-1-168',
      data: { mall_code: _vm.mallCode, location: 'page' }
    }),expression:"{\n      id: '1-11-1-168',\n      data: { mall_code: mallCode, location: 'page' }\n    }"},{name:"tap",rawName:"v-tap",value:({
      id: '1-11-1-169',
      data: { mall_code: _vm.mallCode, location: 'page' }
    }),expression:"{\n      id: '1-11-1-169',\n      data: { mall_code: mallCode, location: 'page' }\n    }"}],staticClass:"page-tips__hint",attrs:{"type":"info","max-rows":1000,"jumpable":!_vm.isLastGoods},nativeOn:{"click":function($event){$event.stopPropagation();return _setup.clickTaxHint.apply(null, arguments)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_setup.taxHint)}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }